import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsView'
})
export default class GtrLeadsView extends GtrSuper {
  data () {
    return {
      loading: false,
      table: {
        search: '',
        headers: [
          { text: 'Attendee', align: 'start', sortable: true, value: 'participant_data.search_data' },
          { text: 'Total Scans', align: 'start', sortable: true, value: 'reverse_leads_summary.total' },
          { text: 'Unique Scans', align: 'start', sortable: true, value: 'reverse_leads_summary.unique' }
        ],
        items: []
      },
      event_uuid: this.$route.params.event_uuid
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.LEADS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Leads Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  async mounted () {
    await this.fetchLeadsAttendee()
  }

  onClickRowHandle (participant) {
    this.$router.push({
      name: 'level-two.modules.leads-by-attendee.scans.index',
      params: { participant_uuid: participant.participant_data.uuid }
    })
  }

  private async fetchLeadsAttendee () {
    try {
      this.$data.loading = true
      this.$data.table.items = await this.$store.dispatch('leads/fetchLeadsAttendee', { event_uuid: this.$data.event_uuid })
      this.$data.table.items.forEach((item) => {
        item.participant_data.search_data = item.participant_data.company + ' ' + item.participant_data.first_name + ' ' + item.participant_data.last_name + ' ' + item.participant_data.email
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async getExportAllLeadsCountsByAttendee () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportAllLeadsCounts', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
